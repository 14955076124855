










































    import { Component, Vue } from 'vue-property-decorator';
import Layout from "@/components/Layout.vue"; // @ is an alias to /src

    @Component({
        components: {
            Layout
        }
    })
    export default class ProductCenter extends Vue {
        bg: string = require('@/assets/product/img/bg.png')
        p1: string = require('@/assets/product/img/p1.png')
    }
